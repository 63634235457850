import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Link from '../localized-link'
import H1 from '../../theming/H1'

export default function Footer({ settings }) {
  return (
    <footer className="c-row c-footer">
      <div className="o-container">
        <div className="o-grid">
          <div className="o-grid__item u-1-of-3-bp3 c-footer__links">
            <Link to="/privacy">
              <FormattedMessage id="page.privacy" />
            </Link>
            <span className="o-separator">{' · '}</span>
            <Link to="/terms">
              <FormattedMessage id="page.terms" />
            </Link>
          </div>
          {settings.registration_number && (
            <div className="o-grid__item u-1-of-3-bp3 u-text-center">
              <span className="c-company-registration">
                {settings.registration_number}
              </span>
            </div>
          )}
          <div
            className={classNames('o-grid__item ', {
              'u-1-of-3-bp3': settings.registration_number,
              'u-2-of-3-bp3': !settings.registration_number,
            })}
          >
            <H1 className="c-logo c-logo--footer">
              <a href="https://sweepbright.com" className="c-logo__link">
                SweepBright
              </a>
            </H1>
          </div>
        </div>
      </div>
    </footer>
  )
}
